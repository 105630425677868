<script setup lang="ts">
const showAnimation = !localStorage.skipSplashscreen

localStorage.skipSplashscreen = true

</script>
<template>
  <CommonSplash :showAnimation="showAnimation">
    <template #screen>
      <slot/>
    </template>
  </CommonSplash>
</template>
